.evaluation {
  position: relative;
  background-color: $grey-lt;

  img.background {
    width: 335px;
    position: absolute;
    left: 0;
    bottom: 0;

    @include tablet-landscape {
      width: 300px;
    }
  }

  .evalution-title-text {
    margin-bottom: 35px;
  }

  .evaluation-box-icon {
    position: relative;
    text-align: center;
    padding: 15px;

    img {
      width: 85px;
      margin-bottom: 15px;
    }

    h3 {
      text-transform: uppercase;
      font-weight: $fw-black;
    }

    p {
      font-size: 14px;
      color: $grey;
      line-height: 18px;
      margin-bottom: 0;
    }

    .show-more {
      font-size: 28px;
      color: $orange;
      font-weight: $fw-black;
    }
  }

  .evaluation-box-download {
    background-color: $orange;
    height: 100%;
    padding: 15px;

    img {
      width: 30px;
      margin: 0 auto 20px auto;
    }

    a {
      display: block;
      height: 100%;
      color: $white;
      line-height: 20px;
      text-align: center;

      @include content-center();
    }
  }

}

.modal {
  max-width: 60%;
  @include box-shadow(none);
  border-radius: 0;
  padding: 85px;

  @include tablet-portrait {
    max-width: 80%;
    padding: 85px 35px;
  }

  @include mobile-portrait-and-landscape {
    max-width: 100%;
    padding: 50px 10px;
    font-size: 14px;
  }

  a.close-modal {
    top: 35px;
    right: 35px;

    @include mobile-portrait-and-landscape {
      top: 10px;
      right: 10px;
    }
  }

  .modal-content {
    h3 {
      font-weight: $fw-black;
      text-transform: uppercase;
    }

    h4 {
      font-weight: $fw-bold;
    }

    ul {
      padding-left: 0;

      li {
        list-style-type: none;
        color: $grey;
        margin-bottom: 10px;

        span {
          color: $orange;
          font-weight: $fw-bold;
        }
      }
    }
  }
}

.blocker {
  background-color: rgba( $black, .2 );
}