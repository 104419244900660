.steps {

  padding-bottom: 35px !important;

  .steps-title-text {
    text-align: center;
    margin-bottom: 35px;
  }

  .step {
    position: relative;
    min-height: 335px;
    padding: 105px 15px 50px 15px;
    margin-bottom: 35px;
    overflow: hidden;
    @include box-shadow(0px 3px 9px -6px $black);

    @include tablet-portrait {
      min-height: 340px;
      padding: 90px 15px 10px 15px;
    }

    @include tablet-landscape {
      min-height: 300px;
    }

    @include mobile-portrait-and-landscape {
      min-height: auto;
      padding: 80px 15px 35px 15px;;
    }

    .step-number {
      font-family: $ff-martel-sans;
      font-size: 50px;
      color: $white;
      position: absolute;
      top: 5px;
      left: 15px;

      @include tablet-portrait {
        font-size: 40px;
      }

      @include mobile-portrait-and-landscape {
        font-size: 30px;
      }
    }

    h3 {
      br {
        @include tablet-portrait {
          display: none;
        }
      }

    }

    p {
      font-size: 14px;
      color: $grey;
    }

    &:before {
      top: -60px;
      left: -55px;
    }

    &.step-one {
      &:before {
        @include circle-deco(145px, rgba( $orange, .3 ));

        @include tablet-portrait {
          @include circle-deco(130px, rgba( $orange, .3 ));
        }

        @include mobile-portrait-and-landscape {
          @include circle-deco(120px, rgba( $orange, .3 ));
        }
      }
    }

    &.step-two {
      &:before {
        @include circle-deco(145px, rgba( $orange, .6 ));

        @include tablet-portrait {
          @include circle-deco(130px, rgba( $orange, .6 ));
        }

        @include mobile-portrait-and-landscape {
          @include circle-deco(120px, rgba( $orange, .6 ));
        }
      }
    }

    &.step-three {
      &:before {
        @include circle-deco(145px, rgba( $orange, 1 ));

        @include tablet-portrait {
          @include circle-deco(130px, rgba( $orange, 1 ));
        }

        @include mobile-portrait-and-landscape {
          @include circle-deco(120px, rgba( $orange, 1 ));
        }
      }
    }
  }

}