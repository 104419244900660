label {
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

input[type=text],
input[type=email],
textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding-left: .5rem;
  background-color: #F1F1F1;
  border: none;
  color: $grey;
  font-size: 14px;
}

input[type=text],
input[type=email] {
  height: 50px;
  line-height: 50px;
}

textarea {
  resize: none;
}

button[type=submit] {
  background-color: $orange;
  border: 1px solid $orange;
  color: $white;
  font-weight: bold;
  padding: .5rem 2.5rem;
  @include transition( all .35s ease-out );
  
  &:hover {
    background-color: rgba( $orange, .95 );
    border: 1px solid rgba( $orange, .95 );
    -webkit-box-shadow: 2px 2px 6px -1px rgba( $black, .8 );
    -moz-box-shadow: 2px 2px 6px -1px rgba( $black, .8 );
    box-shadow: 2px 2px 6px -1px rgba( $black, .8 );
  }
}

.form-alert {

  position: relative;
  font-size: 13px;
  margin-top: 1rem;
  padding: 1rem;
  overflow: hidden;

  &.error {
    border: 1px solid $red;
    color: $red;

    &:after {
      background: $red;
    }
  }

  &.success {
    border: 1px solid $green;
    color: $green;

    &:after {
      background: $green;
    }
  }

  &:after {
    content: '!';
    position: absolute;
    top: -10px;
    right: -10px;
    color: $white;
    font-size: 30px;

    width: 45px;
    height: 45px;
    border-radius: 50%;
    text-align: center;
  }

}

.rgpd {
  font-size: 14px;
  color: $grey;
}


// Fake field
label[for=comment],
input[name=comment]{
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}