.behaviour {

  .behaviour-title-text,
  .behaviour-image {
    @include content-center();
  }

  .behaviour-title-text {
    ul {
      padding-left: 0;

      li {
        position: relative;
        list-style-type: none;
        padding-left: 20px;
        margin-bottom: 15px;

        &:before {
          content: '';
          width: 5px;
          height: 5px;
          background-color: $orange;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          top: 10px;

        }
      }
    }

    p {
      font-size: 14px;
      line-height: 26px;
      color: $grey;

      span {
        display: block;
        font-size: 16px;
        color: $orange;
        margin-top: 35px;

      }
    }
  }

}