.footer {

  .separator {
    text-align: center;
    margin-bottom: 50px;

    img {
      @include mobile-portrait-and-landscape {
        width: 75%;
      }
    }
  }

  .footer-title-text {
    text-align: center;
  }

  //..
  .footer-bottom {
    color: $white;
    background-color: #2C2C2C;
    padding: 30px 0;
    margin-top: 60px;
  }

  .copyright {
    font-size: 14px;
    @include content-center();

    @include tablet-portrait {
      text-align: center;
    }

    @include mobile-portrait-and-landscape {
      text-align: center;
    }
  }

  .logo {
    text-align: center;

    img {
      width: 150px;
    }
  }

  ul.menu {
    text-align: right;
    padding-left: 0;
    margin-top: 35px;

    @include tablet-portrait {
      text-align: center;
    }

    @include mobile-portrait-and-landscape {
      text-align: center;
    }

    li {
      display: inline-block;


      &:first-child {
        margin-right: 10px;
        list-style-type: none;
      }

      &:last-child {
        &:before {
          content: '-';
          margin-right: 10px;
        }
      }

      a {
        font-size: 14px;
        color: $white;
        @include transition( all .3s ease-out );

        &:hover {
          color: $grey;
        }
      }
    }
  }

}