body {
  font-family: $ff-open-sans;
  font-size: 16px;
  line-height: 1.5;
  color: $black;

  @include mobile-portrait {
    line-height: 1.3;
  }
}

a {
  outline: 0;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

h1, h2, h3 {
  font-family: $ff-martel-sans;
  font-weight: $fw-bold;
}


h2 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 30px;

  @include mobile-portrait-and-landscape {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 25px;
  }
}

h3 {
  font-size: 16px;
  line-height: 24px;
}

