.presentation {
  position: relative;
  padding-bottom: 55px;
  background-color: $grey-lt;
  overflow: hidden;

  &:before {
    @include circle-deco( 250px, rgba( $white, .1 ) );
    top: -85px;
    right: -105px;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 37%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $orange;

    @include mobile-portrait-and-landscape {
      content: none;
    }
  }

  .logo {
    width: 170px;

    @include mobile-portrait-and-landscape {
      width: 130px;
    }
  }

  .presentation-header,
  .presentation-content {
    position: relative;
    z-index: 1;
  }

  .presentation-header {
    padding-top: 20px;

    .btn {
      float: right;
      @include cta( $orange, $white );
    }
  }

  .presentation-content {
    margin-top: 25px;

    &:before {
      @include circle-deco( 100px, rgba( $black, .1 ) );
      right: 35px;
      bottom: 0;
    }

    &:after {
      @include circle-deco( 350px, rgba( $white, 0 ) );
      left: 55%;
      bottom: -80%;
      border: 7px solid rgba( $white, .1 );
    }

    @include mobile-portrait-and-landscape {
      &:before,
      &:after {
        content: none;
      }
    }
  }

  .presentation-title,
  .presentation-image {
    @include content-center();
  }

  .presentation-title {
    padding-right: 50px;


    h1 {
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 25px;

      @include mobile-portrait-and-landscape {
        font-size: 25px;
        line-height: 30px;
      }

      span {
        display: block;
        &:first-child {
          font-size: 36px;
          color: $orange;

          @include mobile-portrait-and-landscape {
            font-size: 31px;

          }
        }

        &:last-child {
          font-size: 20px;
          @include mobile-portrait-and-landscape {
            font-size: 16px;
            line-height: 20px;
            margin-top: 15px;
          }
        }
      }
    }

    p {
      font-size: 16px;
      color: $grey;

      span {
        color: $orange;
      }
    }

    .btn {
      @include cta( $white, $orange );
      width: 200px;
    }

    @include tablet-portrait {
      br {
        display: none;
      }
    }

    @include mobile-portrait-and-landscape {
      br {
        display: none;
      }
    }
  }


}