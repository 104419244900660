$grey: #6b6b6b;
$grey-lt: #F9F9F9;
$orange: #f27f00;
$red: #ca3433;
$green: #50c878;

$black: #000000;
$white: #ffffff;

$ff-open-sans: 'Open Sans', sans-serif;
$ff-martel-sans: 'Martel Sans', sans-serif;

$fw-regular: 400;
$fw-bold: 700;
$fw-black: 900;
