// ---
// ...
// ---
@mixin transform($args...){
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-duration($duration){
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -ms-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin transition-delay($duration) {
  -webkit-transition-delay: $duration;
  -moz-transition-delay: $duration;
  -ms-transition-delay: $duration;
  -o-transition-delay: $duration;
  transition-delay: $duration;
}

@mixin box-shadow($arg...){
  -webkit-box-shadow: $arg;
  -moz-box-shadow: $arg;
  box-shadow: $arg;
}

@mixin cta($color, $bg-color){
  background-color: $bg-color;
  color: $color;
  border: 1px solid $bg-color;
  height: 45px;
  padding: 0 25px;
  display: inline-block;
}


@mixin circle-deco($size, $bg-color){
  content: '';
  width: $size;
  height: $size;
  position: absolute;
  background-color: $bg-color;
  border-radius: 50%;
}


// ---
// ...
// ---
@mixin content-center () {
  height: 100%;
  display: -ms-flexbox;
  display: flex;

  -webkit-justify-content: center;
  justify-content: center;

  -ms-flex-pack: center;
  -ms-flex-direction: column;
  flex-direction: column;
}


@mixin button {
  display: inline-block;
  max-width: 285px;
  text-align: center;
  background-color: $blue-petrol;
  color: $white;
  font-size: 18px;
  padding: 15px 45px;
  border-radius: 30px;
  @include transition( all .3s ease-out );

  &:hover {
    color: $white;
    @include box-shadow( 3px 5px 6px -4px $black );
  }
}

// ---
// media queries
// ---


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@mixin desktop {
  @media (min-width: 1281px) { @content }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@mixin laptop-desktop {
  @media (min-width: 1025px) and (max-width: 1280px) { @content }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@mixin tablet-portrait {
  @media (min-width: 768px) and (max-width: 1024px) { @content }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@mixin tablet-landscape {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { @content }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@mixin tablet-mobile-landscape {
  @media (min-width: 481px) and (max-width: 767px) { @content }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@mixin mobile-portrait {
  @media (min-width: 320px) and (max-width: 480px) { @content; }
}

@mixin mobile-portrait-and-landscape {
  @media (min-width: 320px) and (max-width: 767px) { @content }
}